
import { defineComponent, PropType } from "vue-demi";
import { mapGetters } from "vuex";
import { COURSES_ENUM, ROLES_ENUM } from "@/utils/enums";
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "sectionTargets",
  emits: ["selectTarget", "combine", "onuncombine", "saveStudentQuestionnaire"],
  props: {
    sections: {
      required: true,
      type: Array as PropType<Array<dynamicsObject>>,
    },
    targets: {
      type: Array,
      required: true,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
    status: {
      type: Boolean,
      required: true,
    },
    course: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showCombine: false,
      combines: {
        1: true,
        2: true,
        3: false,
      },
    };
  },
  methods: {
    async combineTargets() {
      if (this.targets.length === 3) {
        this.showCombine = !this.showCombine;
      } else if (this.targets.length === 2) {
        this.$emit("combine");
        await this.$store.commit("combineTargets", [1, 2]);
        this.$emit("saveStudentQuestionnaire");
      }
    },
    async combineTargetsMore() {
      this.showCombine = false;
      const targets = Object.entries(this.combines).filter((c) => c[1]);
      if (targets.length < 2)
        return this.$store.commit("createNotification", {
          status: "error",
          message: "Выбрано менее двух целей для объединения!",
        });
      this.$emit("combine");
      await this.$store.commit(
        "combineTargets",
        targets.map((c) => Number(c[0]))
      );
      this.$emit("saveStudentQuestionnaire");
    },
  },
  computed: {
    ...mapGetters(["uncombine"]),
    onCombineTargets() {
      return this.questionnaireMode === "GAP" && this.targets.length > 1;
    },
    onUncombineTargets() {
      return this.uncombine && this.questionnaireMode === "GAP";
    },
    isExpertCapital() {
      return this.course === COURSES_ENUM.ONE && this.role === ROLES_ENUM.EXPERT;
    },
    isAddTarget() {
      return this.targets.length < 3 && this.questionnaireMode === "DEFAULT";
    },
  },
});
