
import { defineComponent, PropType } from 'vue-demi';
import { mapGetters } from 'vuex'
import { dynamicsObject, valueof } from '@/interfaces';
import chart from './Chart.vue';
import { Questionnaire } from '@/interfaces/dto/questionnaire';
import { Instrument } from '@/interfaces/dto/instrument';
import { COURSES_ENUM } from '@/utils/enums';

export default defineComponent({
  name: 'PortfolioCharts',
  props: {
    portfolio: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true
    },
    target: {
      type: Object,
      required: true
    },
    portfolioId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showSectionMore: false,
      showStructure: false,
      colors: [],
      options: [] as Array<dynamicsObject>,
    }
  },
  computed: {
    ...mapGetters(["preview", "currencies", "mixedAssets"]),
    percents() {
      const core = this.portfolio.sections[1].modules.map((m) => m.data).filter(i => i.name && i.price);
      this.setOptions(core);
      return '';
    },
    sectionsPercents() {
      const percents: dynamicsObject = {};
      const core = this.portfolio.sections[1].modules.map((m) => m.data).filter((i) => i[`class_${this.course}_id`] === "stock" && ["Акции", "Отраслевые ETF и БПИФ"].includes(i[`instrument_type_${this.course}`]));
      core.forEach(instrument => {
        if (!percents[instrument[`section_${this.course}`]]) percents[instrument[`section_${this.course}`]] = { amount: 0, list: { } };
        let lot = this.course === COURSES_ENUM.TWO && instrument.lot ? instrument.lot : 1;
        const amount = instrument.price * instrument.number_papers * lot;
        const currentAmount =
          this.course === COURSES_ENUM.ONE
            ? this.getUSDCurrency(amount, instrument[`currency_${this.course}_id`])
            : this.getRUBCurrency(amount, instrument[`currency_${this.course}_id`]);
        percents[instrument[`section_${this.course}`]].list[instrument.name] = currentAmount;
        percents[instrument[`section_${this.course}`]].amount += currentAmount;
      });
      if (Object.values(percents).length > 0) {
        const total = Object.values(percents).reduce((t, n) => t + n.amount, 0);
        for (const key in percents) {
          percents[key].amount = Math.ceil10((percents[key].amount / total) * 100, -1);
          for (const listKey in percents[key].list) {
            const instrument = percents[key].list[listKey];
            percents[key].list[listKey] = Math.ceil10((instrument / total) * 100, -1);
          }
        }
      }
      return percents;
    },
    totalInstruments() {
      let length = 0;
      for (const key in this.sectionsPercents) {
        length += Object.keys(this.sectionsPercents[key].list).length;
      }
      return length;
    }
  },
  created() {
    this.colors = this.preview[this.course === COURSES_ENUM.ONE ? 'firstQuestionnaireColors' : 'secondQuestionnaireColors'];
  },
  methods: {
    setOptions(core: Instrument.Dto[]) {
      const date = Date.now();
      this.showStructure = core.length > 0;
      this.options = [
        { id: `section_percents_${this.portfolioId}_${date}`, courses: ['one', 'two'], action: true, name: 'Структура акций по секторам экономики:', data: this.getCorrectStructurePercents(core, `section_${this.course}`) },
        { id: `stock_risk_percents_${this.portfolioId}_${date}`, courses: ['two'], name: 'Структура портфеля по рисковой части:', data: this.getCorrectStructurePercents(core, `instrument_type_${this.course}`, true) },
        { id: `currency_percents_${this.portfolioId}_${date}`, courses: ['one', 'two'], name: 'Структура портфеля по валютам:', data: this.getCorrectStructurePercents(core, `base_currency_${this.course}_id`) },
        { id: `conserv_percents_${this.portfolioId}_${date}`, courses: ['two'], name: 'Структура портфеля по консервативной части:', data: this.getCorrectStructurePercents(core, `instrument_type_${this.course}`)},
        { id: `country_percents_${this.portfolioId}_${date}`, courses: ['one', 'two'], name: 'Структура портфеля по географии:', data: this.getCorrectStructurePercents(core, `country_${this.course}`) },
        { id: `bond_period_percents_${this.portfolioId}_${date}`, courses: ['two'], name: 'Структура облигаций по сроку:', data: this.getCorrectStructurePercents(core, `matdate`) },
      ]
    },
    getCurrentInstruments(instruments: Array<Instrument.Dto>, type: string, moreFilter = false) {
      if (type === 'matdate')
        return instruments.filter((i) => i[`class_${this.course}_id`] === "bond");
      if (type.includes("section_"))
        return instruments.filter((i) => i[`class_${this.course}_id`] === "stock" && i["section_" + this.course] && ["Акции", "Отраслевые ETF и БПИФ"].includes(i[`instrument_type_${this.course}`]));
      if (type.includes("instrument_type_") && moreFilter) 
        return instruments.filter((i) => i[`class_${this.course}_id`] === "stock" && i["section_" + this.course] && ["Акции", "Отраслевые ETF и БПИФ", "ETF и БПИФ на акции широкого рынка (не отраслевые)"].includes(i[`instrument_type_${this.course}`]));
      if (type.includes("instrument_type_"))
        return instruments.filter((i) => i[`class_${this.course}_id`] === "bond" && i["instrument_type_" + this.course]);
      return instruments;
    },
    getCorrectStructurePercents(instruments: Array<Instrument.Dto>, type: string, moreFilter = false) {
      const result: dynamicsObject = {};
      const copy = this.getCurrentInstruments(instruments, type, moreFilter);
      copy.forEach((instrument) => {
        if (!result[instrument[type]] && type !== 'matdate') result[instrument[type]] = 0;
        let lot = this.course === COURSES_ENUM.TWO && instrument.lot ? instrument.lot : 1;
        const amount = instrument.price * instrument.number_papers * lot;

        const currentAmount =
          this.course === COURSES_ENUM.ONE
            ? this.getUSDCurrency(amount, instrument[`currency_${this.course}_id`])
            : this.getRUBCurrency(amount, instrument[`currency_${this.course}_id`]);

        if (
          (type.includes('class_') || type.includes('section_')) &&
          instrument[`instrument_type_${this.course}`] === "Фонды смешанных активов"
        ) {
          const mixedAsset = this.mixedAssets.find(
            (asset: dynamicsObject) =>
              asset.name.toLowerCase().trim() ===
              instrument.name.toLowerCase().trim()
          );
          if (mixedAsset) {
            const classes = [
              { one: "Акция", two: "Рисковая часть", id: "stock" },
              { one: "Облигация", two: "Консервативная часть", id: "bond" },
              { one: "Альтернативные инвестиции", two: "Защитная часть", id: "alternative" },
            ];
            if (type.includes('section_')) {
              result[instrument[type]] +=
                currentAmount * (mixedAsset.stock / 100);
            }
            if (type.includes('class_')) {
              classes.forEach((classAsset) => {
                if (!result[classAsset[this.course]])
                  result[classAsset[this.course]] = 0;
                result[classAsset[this.course]] +=
                  currentAmount * (mixedAsset[classAsset.id] / 100);
              });
            }
          }
        } else if (type === 'matdate') {
          const date = new Date(instrument.matdate);
          if (+date) {
            const correctDate = new Date();
            correctDate.setDate(correctDate.getDate() + 1460);
            if (correctDate <= date) {
              if (!result['Долгосрочные']) result['Долгосрочные'] = 0;
              result['Долгосрочные'] += currentAmount;
            } else {
              if (!result['Краткосрочные']) result['Краткосрочные'] = 0;
              result['Краткосрочные'] += currentAmount;
            }
          }
        } else {
          result[instrument[type]] += currentAmount;
        }
      });
      if (Object.values(result).length > 0) {
        const total = Object.values(result).reduce((t, n) => t + n);
        for (const key in result) {
          if (Object.hasOwnProperty.call(result, key)) {
            result[key] = Math.ceil10((result[key] / total) * 100, -1);
          }
        }
      }
      if (result && result['Акции'] && moreFilter && type === `instrument_type_${this.course}`) {
        Object.defineProperty(result, 'Акции отдельных компаний',
          Object.getOwnPropertyDescriptor(result, 'Акции') || '');
        delete result['Акции'];
      }
      return result;
    },
    getUSDCurrency(amount: number, currency: string) {
      const rates = this.currencies;
      return Math.ceil10(amount / rates[currency], -1);
    },
    getRUBCurrency(amount: number, currency: string) {
      const rates = this.currencies;
      return Math.ceil10((amount / rates[currency]) * rates["RUB"], -1);
    },
  },
  components: {
    chart
  }
})
