import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "portfolio--table-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portfolio_info = _resolveComponent("portfolio-info")!
  const _component_portfolio_categories = _resolveComponent("portfolio-categories")!
  const _component_portfolio_diversification = _resolveComponent("portfolio-diversification")!
  const _component_portfolio_comments = _resolveComponent("portfolio-comments")!
  const _component_portfolio_charts = _resolveComponent("portfolio-charts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_portfolio_info, {
      portfolioId: _ctx.portfolioId,
      course: _ctx.course,
      portfolio: _ctx.portfolio,
      target: _ctx.target
    }, null, 8, ["portfolioId", "course", "portfolio", "target"]),
    _createVNode(_component_portfolio_categories, {
      portfolioId: _ctx.portfolio.id,
      portfolioName: _ctx.portfolioId,
      target: _ctx.target,
      course: _ctx.course,
      core: _ctx.portfolio.sections[0].modules[0].data.core,
      categories: _ctx.getInstruments
    }, null, 8, ["portfolioId", "portfolioName", "target", "course", "core", "categories"]),
    _createVNode(_component_portfolio_diversification, {
      course: _ctx.course,
      portfolioId: _ctx.portfolio.id,
      portfolio: _ctx.portfolio,
      target: _ctx.target
    }, null, 8, ["course", "portfolioId", "portfolio", "target"]),
    _createVNode(_component_portfolio_comments, {
      portfolioId: _ctx.portfolio.id,
      course: _ctx.course,
      portfolio: _ctx.portfolio,
      target: _ctx.target
    }, null, 8, ["portfolioId", "course", "portfolio", "target"]),
    _createVNode(_component_portfolio_charts, {
      portfolioId: _ctx.portfolio.id,
      course: _ctx.course,
      portfolio: _ctx.portfolio,
      target: _ctx.target
    }, null, 8, ["portfolioId", "course", "portfolio", "target"])
  ]))
}