
import { defineComponent, PropType } from 'vue-demi';
import { mapGetters } from 'vuex';
import { dynamicsObject, valueof } from '@/interfaces';
import { getCorrectPercentBalance, getCorrectPercentCurrencies } from '../../../table/modules/container/calculation';
import { Questionnaire } from '@/interfaces/dto/questionnaire';
import { COURSES_ENUM } from '@/utils/enums';

export default defineComponent({
  name: 'PortfolioBalance',
  props: {
    portfolio: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true
    },
    targetCurrencyId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      titles: {
        stock: {
          one: 'акций',
          two: 'рисковой части'
        },
        bond: {
          one: 'облигаций',
          two: 'консервативной части'
        },
        alternative: {
          one: 'защитного класса',
          two: 'защитной части'
        }
      },
      defaults: {
        first: 'Процент считается по сумме всех инструментов',
        second: 'Процент считается по сумме всех инструментов ядра портфеля'
      }
    }
  },
  computed: {
    ...mapGetters(['mixedAssets']),
    percentBalance() {
      const percents = {
        0: this.portfolio.sections[0].modules[0].data.core,
        1: this.portfolio.sections[0].modules[0].data.tactic
      }
      return percents;
    },
    percentTypes() {
      const coreArray = this.portfolio.sections[1].modules.map(m => m.data).filter(m => m.price);
      return getCorrectPercentBalance(
        this.targetCurrencyId,
        this.course,
        this.mixedAssets,
        coreArray.filter((i: dynamicsObject) => i[`class_${this.course}_id`] === 'stock'),
        coreArray.filter((i: dynamicsObject) => i[`class_${this.course}_id`] === 'bond'),
        coreArray.filter((i: dynamicsObject) => i[`class_${this.course}_id`] === 'alternative')
      );
    },
    percentCurrencies() {
      const coreArray = this.portfolio.sections[1].modules;
      const tacticArray = this.portfolio.id === "expertPortfolio" ? [] : this.portfolio.sections[2].modules;
      const array = coreArray.concat(tacticArray).map(m => m.data).filter(m => m.price);
      return getCorrectPercentCurrencies(this.targetCurrencyId, array, this.course);
    }
  }
})
