
import { defineComponent, inject, PropType } from 'vue-demi';
import { getCorrectInstrumentsCurrency } from '../../../../../../table/modules/container/calculation';
import { Questionnaire } from '@/interfaces/dto/questionnaire';
import categoryTable from "./table/index.vue";
import { COURSES_ENUM } from '@/utils/enums';
import { valueof } from '@/interfaces';

export default defineComponent({
  name: "PortfolioCategory",
  props: {
    category: {
      type: Object as PropType<Questionnaire.QSection>,
      required: true,
    },
    portfolioId: {
      type: String,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
  },
  computed: {
    totalCategory() {
      return getCorrectInstrumentsCurrency(this.category.modules.map(m => m.data).filter(m => m.price), String(this.targetCurrencyId), this.course);
    },
    currentName() {
      if (this.category.name.includes('ядра')) return {name: 'Ядро', total: 'ядро'}
      else return {name: 'Тактическая часть', total: 'тактика'}
    },
    currentText() {
      return `Итого 
        ${ this.currentName.total }: 
        ${ this.totalCategory } 
        ${ this.targetCurrencySign || '' } 
        (${Math.ceil10(((Number(this.totalCategory.replace(/ /g, '')) / Number(this.total.replace(/ /g, ''))) * 100), -1) || 0} %)
      `
    }
  },
  methods: {
    openUrl(src: string) {
      window.open(src);
    },
  },
  setup() {
    const targetCurrencyId = inject('targetCurrencyId');
    const targetCurrencySign = inject('targetCurrencySign');
    const total: string = inject('total') || '';
    return { targetCurrencyId, targetCurrencySign, total }
  },
  components: {
    categoryTable,
  },
});
