import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f8edf6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portfolio_table = _resolveComponent("portfolio-table")!

  return (_openBlock(), _createBlock(_Transition, { name: "portfolioAnimation" }, {
    default: _withCtx(() => [
      (_ctx.currentSection && _ctx.checkSection(_ctx.portfolioId))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["portfolio color", { dNone: !_ctx.checkSection(_ctx.portfolioId) }]),
            "data-id": _ctx.portfolioId
          }, [
            _createVNode(_component_portfolio_table, {
              class: _normalizeClass({ dNone: !_ctx.checkSection(_ctx.portfolioId) }),
              course: _ctx.course,
              targetId: _ctx.targetId,
              portfolio: _ctx.portfolio,
              portfolioId: _ctx.portfolioId
            }, null, 8, ["class", "course", "targetId", "portfolio", "portfolioId"])
          ], 10, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}