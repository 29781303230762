
import { defineComponent, PropType } from "vue-demi";
import { Questionnaire } from "@/interfaces/dto/questionnaire";

export default defineComponent({
  name: 'PortfolioComments',
  props: {
    portfolio: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true
    },
    course: {
      type: String,
      required: true
    },
    target: {
      type: Object,
      required: true
    },
    portfolioId: {
      type: String,
      required: true,
    },
  },
  computed: {
    comments() {
      return this.portfolio.sections.filter(s => s.default.includes('-comment'));
    }
  },
  methods: {
    fromComment(from: string) {
      const offset = this.jq(`table[data-id="${from.replace('-comment', '')}"]`).offset() || { top: 150 };
      this.jq('html, body').animate({ scrollTop: offset.top - 150 }, 400);
    }
  }
})
