
import { defineComponent } from 'vue-demi';
import { mapGetters } from 'vuex';
import { mask } from "vue-the-mask";

export default defineComponent({
  emits: [
    "selectDropElement",
    "toggleDrop",
    "formula",
    "inputKeyUp",
    "inputFocus",
  ],
  directives: {
    mask
  },
  name: "QuestionnaireInput",
  props: {
    input: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    showName: {
      type: Boolean,
      required: true,
    },
    gap: {
      type: Boolean,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => ({
        show: true,
      }),
    },
    targetId: {
      type: Number,
    },
    targetCurrencySign: {
      type: String,
      default: "",
    },
    dataStudent: {
      type: Object,
      default: () => ({}),
    },
    expert: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    inputKeyUp(event: any) {
      this.$emit('inputKeyUp', { event, input: this.input });
    },
    onlyNumber ($event: any) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    }
  },
  computed: mapGetters(['questionnaireStatus']),
});
