import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26f95cd9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "target--type color" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionnaireSections = _resolveComponent("QuestionnaireSections")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.type.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_QuestionnaireSections, {
        questionnaireMode: _ctx.questionnaireMode,
        course: _ctx.course,
        sections: _ctx.type.sections,
        sectionsStudent: _ctx.typeStudent.sections,
        id: "target-type",
        targetId: _ctx.targetId
      }, null, 8, ["questionnaireMode", "course", "sections", "sectionsStudent", "targetId"])
    ])
  ]))
}