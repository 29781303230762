import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionnaireSection = _resolveComponent("QuestionnaireSection")!

  return (_openBlock(), _createElementBlock("div", {
    class: "sections",
    "data-target": _ctx.targetId
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, indexSection) => {
      return (_openBlock(), _createBlock(_component_QuestionnaireSection, {
        key: indexSection,
        section: section,
        sectionStudent: _ctx.sectionsStudent[indexSection],
        targetId: _ctx.targetId,
        portfolioId: _ctx.portfolioId,
        sectionStatus: _ctx.sectionStatus,
        course: _ctx.course,
        indexSection: indexSection,
        questionnaireMode: _ctx.questionnaireMode,
        targetCurrencySign: _ctx.targetCurrencySign
      }, null, 8, ["section", "sectionStudent", "targetId", "portfolioId", "sectionStatus", "course", "indexSection", "questionnaireMode", "targetCurrencySign"]))
    }), 128))
  ], 8, _hoisted_1))
}