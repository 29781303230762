
import { defineComponent, PropType } from "vue-demi";
import TargetMain from "./main/index.vue";
import TargetType from "./type/index.vue";
import TargetConclusion from "./conclusion/index.vue";
import TargetPortfolios from "./portfolio/index.vue";
import { Questionnaire } from "@/interfaces/dto/questionnaire";
import { valueof } from "@/interfaces";
import { COURSES_ENUM } from "@/utils/enums";

export default defineComponent({
  name: "QuestionnaireTarget",
  props: {
    target: {
      type: Object as PropType<Questionnaire.QTarget>,
      required: true,
    },
    targetStudent: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
    status: {
      type: Boolean,
      required: true,
    },
    checkSection: {
      type: Function,
      required: true,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  computed: {
    checkInstruments() {
      const sections = [1, 2];
      for (const section of sections) {
        this.target.portfolios.existing.sections[section].modules.forEach(m => {
          const status = !this.target.portfolios.student.sections[section].modules.find(mm => mm.data.name.trim().toLowerCase() === m.data.name.trim().toLowerCase());
          m.data.notStudent = status;
        });
      }
      return ''
    },
    targetCurrencySign() {
      return this.target.main.data.currency_sign;
    },
    targetCurrencyId() {
      return this.target.main.data.currency_id;
    },
  },
  components: {
    TargetMain,
    TargetType,
    TargetConclusion,
    TargetPortfolios,
  },
});
