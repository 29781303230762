import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07c02989"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "questionnaire--container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sections = _resolveComponent("Sections")!
  const _component_Member = _resolveComponent("Member")!
  const _component_section_targets = _resolveComponent("section-targets")!
  const _component_Targets = _resolveComponent("Targets")!
  const _component_Insurance = _resolveComponent("Insurance")!
  const _component_Comment = _resolveComponent("Comment")!
  const _component_QuestionnaireTable = _resolveComponent("QuestionnaireTable")!
  const _component_Buttons = _resolveComponent("Buttons")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mode === 'list')
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          (_ctx.course === _ctx.COURSES_ENUM.TWO && _ctx.role === 'EXPERT')
            ? (_openBlock(), _createBlock(_component_Sections, {
                key: 0,
                sections: _ctx.sections,
                onSelectQuestionnaireSection: _ctx.selectQuestionnaireSection
              }, null, 8, ["sections", "onSelectQuestionnaireSection"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Member, {
            class: _normalizeClass({ dNone: !_ctx.checkSection('target') }),
            course: _ctx.course,
            questionnaireMode: _ctx.questionnaireMode,
            memberStudent: _ctx.questionnaireStudent.student,
            role: _ctx.role,
            member: _ctx.questionnaire.student,
            status: _ctx.status
          }, null, 8, ["class", "course", "questionnaireMode", "memberStudent", "role", "member", "status"]),
          (_ctx.course === _ctx.COURSES_ENUM.ONE)
            ? (_openBlock(), _createBlock(_component_section_targets, {
                key: 1,
                sections: _ctx.sectionsTargets,
                course: _ctx.course,
                role: _ctx.role,
                status: _ctx.status,
                questionnaireMode: _ctx.questionnaireMode,
                targets: _ctx.questionnaire.targets,
                onSelectTarget: _ctx.selectTarget,
                onCombine: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('combine'))),
                onSaveStudentQuestionnaire: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('saveStudentQuestionnaire'))),
                onOnuncombine: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('uncombine')))
              }, null, 8, ["sections", "course", "role", "status", "questionnaireMode", "targets", "onSelectTarget"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Targets, {
            checkSection: _ctx.checkSection,
            course: _ctx.course,
            targets: _ctx.questionnaire.targets,
            targetsStudent: _ctx.questionnaireStudent.targets,
            questionnaireMode: _ctx.questionnaireMode,
            status: _ctx.status,
            role: _ctx.role
          }, null, 8, ["checkSection", "course", "targets", "targetsStudent", "questionnaireMode", "status", "role"]),
          (_ctx.questionnaireVersion === 'new' && _ctx.course === _ctx.COURSES_ENUM.ONE)
            ? (_openBlock(), _createBlock(_component_Insurance, {
                key: 2,
                insurance: _ctx.questionnaire.insuranceProduct,
                insuranceStudent: _ctx.questionnaireStudent.insuranceProduct,
                questionnaireMode: _ctx.questionnaireMode,
                status: _ctx.status
              }, null, 8, ["insurance", "insuranceStudent", "questionnaireMode", "status"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Comment, {
            questionnaireMode: _ctx.questionnaireMode,
            comment: _ctx.questionnaire.comment,
            status: _ctx.status
          }, null, 8, ["questionnaireMode", "comment", "status"])
        ]))
      : (_openBlock(), _createBlock(_component_QuestionnaireTable, {
          key: 1,
          targets: _ctx.questionnaire.targets,
          insurance: _ctx.questionnaire.insuranceProduct.module.data,
          status: _ctx.status,
          course: _ctx.course,
          onAddStudentTarget: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('addStudentTarget')))
        }, null, 8, ["targets", "insurance", "status", "course"])),
    _createVNode(_component_Buttons, {
      onModeChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('modeChange'))),
      onFinish: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('finish'))),
      pending: _ctx.pending,
      mode: _ctx.mode,
      status: _ctx.status
    }, null, 8, ["pending", "mode", "status"])
  ], 64))
}