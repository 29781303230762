import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "target--portfolios"
}
const _hoisted_2 = {
  key: 1,
  class: "target--portfolios"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portfolio_one = _resolveComponent("portfolio-one")!
  const _component_portfolio_two = _resolveComponent("portfolio-two")!

  return (_ctx.isOneCourse)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portfolios, (portfolio, key) => {
          return (_openBlock(), _createBlock(_component_portfolio_one, {
            key: key,
            portfolio: portfolio,
            portfolioStudent: _ctx.portfoliosStudent[key],
            portfolioId: key,
            targetId: _ctx.targetId,
            course: _ctx.course,
            sectionStatus: _ctx.sectionStatus,
            sectionStudentStatus: _ctx.sectionStudentStatus,
            checkSection: _ctx.checkSection,
            questionnaireMode: _ctx.questionnaireMode,
            role: _ctx.role,
            targetCurrencyId: _ctx.targetCurrencyId
          }, null, 8, ["portfolio", "portfolioStudent", "portfolioId", "targetId", "course", "sectionStatus", "sectionStudentStatus", "checkSection", "questionnaireMode", "role", "targetCurrencyId"]))
        }), 128))
      ]))
    : (_ctx.isTwoCourse)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portfolios, (portfolio, key) => {
            return (_openBlock(), _createBlock(_component_portfolio_two, {
              key: key,
              portfolio: portfolio,
              portfolioStudent: _ctx.portfoliosStudent[key],
              portfolioId: key,
              targetId: _ctx.targetId,
              course: _ctx.course,
              sectionStatus: _ctx.sectionStatus,
              sectionStudentStatus: _ctx.sectionStudentStatus,
              checkSection: _ctx.checkSection,
              questionnaireMode: _ctx.questionnaireMode,
              role: _ctx.role,
              targetCurrencyId: _ctx.targetCurrencyId
            }, null, 8, ["portfolio", "portfolioStudent", "portfolioId", "targetId", "course", "sectionStatus", "sectionStudentStatus", "checkSection", "questionnaireMode", "role", "targetCurrencyId"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
}