import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4935db99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "target--conclusion color" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionnaireSections = _resolveComponent("QuestionnaireSections")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isStudent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.conclusion.name), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_QuestionnaireSections, {
        course: _ctx.course,
        questionnaireMode: _ctx.questionnaireMode,
        sections: _ctx.conclusion.sections,
        sectionsStudent: _ctx.conclusionStudent.sections,
        targetCurrencySign: _ctx.targetCurrencySign,
        id: "target-conclusion",
        targetId: _ctx.targetId
      }, null, 8, ["course", "questionnaireMode", "sections", "sectionsStudent", "targetCurrencySign", "targetId"])
    ])
  ]))
}