
import { defineComponent } from "vue-demi";
import QuestionnairenInputs from "../common/inputs/index.vue";

export default defineComponent({
  name: "QuestionnaireInsurance",
  props: {
    insurance: {
      type: Object,
      required: true,
    },
    insuranceStudent: {
      type: Object,
      required: true,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
  },
  computed: {
    currectInsurance() {
      return this.questionnaireMode === "DEFAULT"
        ? this.insurance
        : this.insuranceStudent;
    },
  },
  components: {
    QuestionnairenInputs,
  },
});
