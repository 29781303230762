import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a0db816"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calculation" }
const _hoisted_2 = { class: "currencies" }
const _hoisted_3 = { class: "currency" }
const _hoisted_4 = { class: "currency" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentCurrencies, (currency) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "currency",
          key: currency.id
        }, [
          _createElementVNode("span", null, "Сумма инструментов в " + _toDisplayString(currency.id) + ": " + _toDisplayString(currency.value) + " (" + _toDisplayString(currency.count) + " шт.)", 1)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, "Остаток " + _toDisplayString(_ctx.remainder.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, " ")) + " " + _toDisplayString(_ctx.mainCurrency), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, "Итого " + _toDisplayString(_ctx.mainAmount.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, " ")) + " " + _toDisplayString(_ctx.mainCurrency), 1)
      ])
    ])
  ]))
}