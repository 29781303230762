import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c668365"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "totalPercent"
}
const _hoisted_2 = { class: "categories" }
const _hoisted_3 = ["name"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portfolio_category = _resolveComponent("portfolio-category")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.portfolioId !== 'existingPortfolio')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Вы заполнили " + _toDisplayString(_ctx.portfolioId === 'expertPortfolio' ? 'ядро' : 'портфель') + " на " + _toDisplayString(_ctx.totalPercent) + "%, осталось " + _toDisplayString(_ctx.leftTotalPercent) + "% ", 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
        return (_openBlock(), _createBlock(_component_portfolio_category, {
          key: category.default,
          category: category,
          portfolioId: _ctx.portfolioId,
          course: _ctx.course
        }, null, 8, ["category", "portfolioId", "course"]))
      }), 128)),
      (_ctx.onTacticFiles)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["tactic", {error: !_ctx.target.portfolios[_ctx.portfolioName].sections[2].file}])
          }, [
            _createElementVNode("label", null, [
              _createElementVNode("input", {
                type: "file",
                name: 'tactic-' + _ctx.portfolioId,
                ref: 'tactic-' + _ctx.portfolioId,
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitter.emit(`changeTacticFile`, { portfolioName: _ctx.portfolioName, file: _ctx.getFile })))
              }, null, 40, _hoisted_3),
              (_ctx.target.portfolios[_ctx.portfolioName].sections[2].file)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Изменить файл"))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, "Прикрепить файлы"))
            ]),
            (_ctx.target.portfolios[_ctx.portfolioName].sections[2].file)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "delete",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitter.emit(`deleteTacticFile`, { portfolioName: _ctx.portfolioName })))
                }, "Удалить файл"))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.portfolioId !== 'expertPortfolio')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["total", _ctx.portfolioId])
          }, "ВСЕГО (ЯДРО+ТАКТИКА): " + _toDisplayString(_ctx.total) + " " + _toDisplayString(_ctx.targetCurrencySign), 3))
        : _createCommentVNode("", true)
    ])
  ], 64))
}