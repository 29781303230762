
import { mapGetters } from 'vuex';
import { defineComponent, PropType, inject } from 'vue-demi';
import { currectSignCurrency, getCorrectInstrumentsCurrency, numberSpaces, getCurrency } from '@/components/questionnaire/modules/container/table/modules/container/calculation';
import { Instrument } from '@/interfaces/dto/instrument';
import { dynamicsObject, valueof } from '@/interfaces';
import { COURSES_ENUM } from '@/utils/enums';
import { Type } from '../../../../interfaces';

export default defineComponent({
  name: "TableElement",
  props: {
    instrumentArray: {
      type: Array as PropType<Array<Instrument.Dto>>,
      required: true,
    },
    type: {
      type: Object as PropType<Type>,
    },
    portfolioId: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      required: true
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
  },
  setup() {
    const targetCurrencyId = inject('targetCurrencyId');
    const targetId = inject('targetId');
    const targetCurrencySign = inject('targetCurrencySign');
    const status = inject('status');
    const total: string = inject('total') || '';
    const totalInstruments = inject('totalInstruments');
    const targetTotal = inject('targetTotal');
    const studentTotal = inject('studentTotal');
    const expertTotal = inject('expertTotal');
    return {
      targetCurrencyId, targetId, targetCurrencySign, status, total, totalInstruments, targetTotal, studentTotal, expertTotal
    }
  },
  data() {
    return {
      lengths: [
        { id: 'one', type: true, number: 25 },
        { id: 'one', type: false, number: 10 },
        { id: 'two', type: true, number: 58 },
        { id: 'two', type: false, number: 5 },
      ],
      hiddens: {
        stock: false,
        bond: false,
        alternative: false,
        mixed: false,
      },
      onDublicateAllInstrument: !!(this.instrumentArray.filter(i => !i.dublicateExpert).length === 0)
    }
  },
  mounted() {
    this.jq('.instrument').toArray().forEach(i => {
      const element = this.jq(i);
      const name = String(element.attr('data-name'));
      const variables = [`currency_${this.course}`, `base_currency_${this.course}`, `class_${this.course}`, `instrument_type_${this.course}`, `country_${this.course}`, `section_${this.course}`];
      
      const instrument = this.instrumentArray.find(i => i.name.trim().toLowerCase() === name.trim().toLowerCase());
      const cinstrument = this.instruments.find((i: dynamicsObject) => i.name.trim().toLowerCase() === name.trim().toLowerCase());
      for (const [index, variable] of variables.entries()) {
        if (cinstrument && instrument && variable.includes('currency_') && !!(cinstrument && (cinstrument[variable] !== instrument[variable] || cinstrument[variable + '_id'] !== instrument[variable + '_id']))) {
          if (cinstrument[variable] !== instrument[variable]) element.children()[2 + index].className = 'divergences';
          if (cinstrument[variable + '_id'] !== instrument[variable + '_id']) element.children()[2 + index].className = 'error';
        }
        if (cinstrument && instrument && !!(cinstrument && cinstrument[variable] !== instrument[variable])) {
          element.children()[2 + index].className = 'divergences';
        }
      }
    });
  },
  computed: {
    ...mapGetters(['instruments']),
    totalPortfolio() {
      return this.portfolioId === 'expertPortfolio' ? this.targetTotal : this.studentTotal;
    },
    totalType() {
      return getCorrectInstrumentsCurrency(this.instrumentArray, String(this.targetCurrencyId), this.course);
    },
    currentText() {
      return `
        ${ this.totalType } 
        ${ this.targetCurrencySign || '' }
        (${Number(((Number(this.totalType.replace(/ /g, '')) / Number(this.total.replace(/ /g, ''))) * 100).toFixed(1)) || 0} %)
      `;
    },
  },
  methods: {
    currectSignCurrency,
    getCurrentMatDate(matdate: string) {
      if (!matdate) return 'Не указан';
      const date = new Date(matdate);
      if (isNaN(date.getMonth())) return 'Некорректно';
      const correctDate = new Date();
      correctDate.setDate(correctDate.getDate() + 1460);
      return correctDate <= date ? 'Долгосрочная' : 'Краткосрочная';
    },
    toComment() {
      const comments = this.jq('.comments').offset() || { top: 120 };
      this.jq('html, body').animate({ scrollTop: comments.top - 120 }, 400);
    },
    async dublicateAllInstrument() {
      for (const instrument of this.instrumentArray) {
        instrument.dublicateExpert = this.onDublicateAllInstrument;
        await this.dublicateInstrument('expert', instrument, true);
      }
      this.onDublicateAllInstrument = !!(this.instrumentArray.filter(i => !i.dublicateExpert).length === 0);
    },
    async dublicateInstrument(portfolioId: string, instrument: Instrument.Dto, all = false) {
      const price = getCurrency(instrument, String(this.targetCurrencyId), this.course);
      const percent = Number(((price / Number(this.expertTotal)) * 100).toFixed(2));
      await this.$store.commit('dublicateTableInstrument', { instrument, percent, targetId: this.targetId, type: this.type, portfolioId, from: this.portfolioId, all });
      if (!all) {
        this.onDublicateAllInstrument = !!(this.instrumentArray.filter(i => !i.dublicateExpert).length === 0);
      }
    },
    correctLength(type: any) {
      const length = this.lengths.find(l => l.id === this.course && l.type === !!type);
      if (!length) return 30;
      return length.number;
    },
    currentAmount(instrument: Instrument.Dto) {
      const lot = (this.course === COURSES_ENUM.TWO && instrument.lot) ? instrument.lot : 1;
      return instrument.price * lot * instrument.number_papers;
    },
    correctPercent(instrument: Instrument.Dto) {
      const price = getCurrency(instrument, String(this.targetCurrencyId), this.course);
      if (!price) return price;
      const percent = ((price / Number(this.total.replace(/ /g, ''))) * 100).toFixed(1);
      instrument.percent = Number(percent);
      return percent;
    },
    modal(id: string, data: dynamicsObject | null) {
      this.$store.commit('createModal', { id, data })
    },
    numberSpaces
  },
});
