
import { defineComponent, PropType } from "vue-demi";
import { mapGetters } from "vuex";
import { Questionnaire } from "@/interfaces/dto/questionnaire";
import portfolioTable from "./table/index.vue";
import { COURSES_ENUM } from "@/utils/enums";
import { valueof } from "@/interfaces";

export default defineComponent({
  name: "PortfolioTwo",
  props: {
    portfolio: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true,
    },
    portfolioStudent: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true,
    },
    portfolioId: {
      type: String,
      required: true,
    },
    targetId: {
      type: Number,
      required: true,
    },
    sectionStatus: {
      type: Object,
      required: true,
    },
    sectionStudentStatus: {
      type: Object,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
    checkSection: {
      type: Function,
      required: true,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    targetCurrencyId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["questionnaireStatus", "questionnaireVersion"]),
    portfolioStatus() {
      return this.portfolioId !== "expert";
    },
    currentSection() {
      return this.questionnaireMode === "GAP"
        ? this.sectionStudentStatus[this.portfolioId] !== -1
        : this.sectionStatus[this.portfolioId] !== -1;
    },
  },
  components: {
    portfolioTable,
  },
});
