
import { defineComponent, PropType } from "vue-demi";
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "QuestionnaireSections",
  emits: ["selectQuestionnaireSection"],
  props: {
    sections: {
      required: true,
      type: Array as PropType<Array<dynamicsObject>>,
    },
  },
});
