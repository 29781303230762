
import { defineComponent, PropType } from "vue-demi";
import { mapGetters } from "vuex";
import { Questionnaire } from "@/interfaces/dto/questionnaire";
import {
  getCorrectPercentBalance,
  getCorrectPercentCurrencies,
} from "../../../../../../table/modules/container/calculation";
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "PortfolioDiversification",
  props: {
    portfolio: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true,
    },
    course: {
      type: String,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
    portfolioId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      defaults: {
        first: "Процент считается по сумме всех инструментов",
        second: "Процент считается по сумме всех инструментов ядра портфеля",
      },
    };
  },
  methods: {
    setPercents(percents: dynamicsObject) {
      this.portfolio.sections[0].modules[0].data.core = percents["0"]
        ? percents["0"].replace("%", "")
        : NaN;
      this.portfolio.sections[0].modules[0].data.tactic = percents["1"]
        ? percents["1"].replace("%", "")
        : NaN;
    },
  },
  computed: {
    ...mapGetters(["mixedAssets"]),
    percentBalance() {
      const coreArray = this.portfolio.sections[1].modules
        .map((m) => m.data)
        .filter((m) => m.price);
      const tacticArray = this.portfolio.sections[2].modules
        .map((m) => m.data)
        .filter((m) => m.price);
      const percents = getCorrectPercentBalance(
        this.target.main.data.currency_id,
        this.course,
        this.mixedAssets,
        coreArray,
        tacticArray
      );
      this.setPercents(percents);
      return percents;
    },
    percentTypes() {
      const coreArray = this.portfolio.sections[1].modules
        .map((m) => m.data)
        .filter((m) => m.price);
      return getCorrectPercentBalance(
        this.target.main.data.currency_id,
        this.course,
        this.mixedAssets,
        coreArray.filter((i) => i[`class_${this.course}_id`] === "stock"),
        coreArray.filter((i) => i[`class_${this.course}_id`] === "bond"),
        coreArray.filter((i) => i[`class_${this.course}_id`] === "alternative")
      );
    },
    percentCurrencies() {
      const coreArray = this.portfolio.sections[1].modules;
      const tacticArray = this.portfolio.sections[2].modules;
      const array = coreArray
        .concat(tacticArray)
        .map((m) => m.data)
        .filter((m) => m.price);
      return getCorrectPercentCurrencies(
        this.target.main.data.currency_id,
        array,
        this.course
      );
    },
  },
});
