
import { defineComponent, PropType } from "vue-demi";
import { valueof } from "@/interfaces";
import { COURSES_ENUM } from "@/utils/enums";
import QuestionnaireInputs from "../../common/inputs/index.vue";
import { Questionnaire } from "@/interfaces/dto/questionnaire";

export default defineComponent({
  name: "TargetMain",
  props: {
    main: {
      type: Object as PropType<Questionnaire.QSectionModules>,
      required: true,
    },
    mainStudent: {
      type: Object as PropType<Questionnaire.QSectionModules>,
      required: true,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
    targetId: {
      type: Number,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  components: {
    QuestionnaireInputs,
  },
});
