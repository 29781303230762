import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "targets",
  "data-id": "targets"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Target = _resolveComponent("Target")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Target, {
      key: _ctx.target.id,
      target: _ctx.target,
      targetStudent: _ctx.targetsStudent[_ctx.targetIndex],
      number: _ctx.targetIndex,
      course: _ctx.course,
      status: _ctx.status,
      questionnaireMode: _ctx.questionnaireMode,
      checkSection: _ctx.checkSection,
      role: _ctx.role
    }, null, 8, ["target", "targetStudent", "number", "course", "status", "questionnaireMode", "checkSection", "role"])
  ]))
}