import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e80fd3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sections flex" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["section", section.id]),
        onClick: ($event: any) => (_ctx.$emit('selectQuestionnaireSection', section.id)),
        key: section.id
      }, _toDisplayString(section.name), 11, _hoisted_2))
    }), 128))
  ]))
}