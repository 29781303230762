
import { defineComponent, PropType } from "vue-demi";
import { mapGetters } from "vuex";
import portfolioBalance from "./balance.vue";
import QuestionnaireSections from "../../common/sections/index.vue";
import PortfolioCalculation from "./portfolioCalculation.vue";
import portfolioTable from './table/index.vue';
import { valueof } from "@/interfaces";
import { COURSES_ENUM, ROLES_ENUM } from "@/utils/enums";
import { Questionnaire } from "@/interfaces/dto/questionnaire";

export default defineComponent({
  name: "QuestionnairePortfolio",
  props: {
    portfolio: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true,
    },
    portfolioStudent: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true,
    },
    portfolioId: {
      type: String,
      required: true,
    },
    targetId: {
      type: Number,
      required: true,
    },
    sectionStatus: {
      type: Object,
      required: true,
    },
    sectionStudentStatus: {
      type: Object,
      required: true
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
    checkSection: {
      type: Function,
      required: true,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    targetCurrencyId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      status: false,
    };
  },
  computed: {
    ...mapGetters(["questionnaireStatus", "questionnaireVersion"]),
    portfolioStatus() {
      return (
        (this.course === COURSES_ENUM.TWO && this.portfolioId !== "expert") ||
        (this.course === COURSES_ENUM.ONE && this.portfolioId === "existing")
      );
    },
    currentSection() {
      return this.questionnaireMode === 'GAP' ? this.sectionStudentStatus[this.portfolioId] !== -1 : this.sectionStatus[this.portfolioId] !== -1;
    },
    isCalculationAccess() {
      return this.course === COURSES_ENUM.TWO && this.role !== ROLES_ENUM.STUDENT && this.portfolioId !== 'expert'
    }
  },
  methods: {
    onSectionStatus(portfolioId: string, status: number) {
      if (status === 1) {
        this.jq(`[data-id="${portfolioId}"] .portfolio--container [data-target="${this.targetId}"] input,
           [data-id="${portfolioId}"] .portfolio--container [data-target="${this.targetId}"] textarea`).prop('disabled', 'false');
        this.$store.commit('onQuestionnaireStatus', false);
        setTimeout(() => {
          this.$store.commit('onQuestionnaireStatus', true);
        }, 100);
      }
      this.$store.commit('sectionPortfolio', {targetId: this.targetId, portfolioId, status})
    }
  },
  components: {
    QuestionnaireSections,
    PortfolioCalculation,
    portfolioBalance,
    portfolioTable
  },
});
