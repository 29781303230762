
import { defineComponent, PropType } from "vue-demi";
import { COURSES_ENUM, ROLES_ENUM } from "@/utils/enums";
import QuestionnairenInputs from "../common/inputs/index.vue";
import { valueof } from "@/interfaces";

export default defineComponent({
  name: "Member",
  props: {
    member: {
      type: Object,
      required: true,
    },
    memberStudent: {
      type: Object,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
  },
  computed: {
    color() {
      return this.course === COURSES_ENUM.TWO && this.role !== ROLES_ENUM.STUDENT;
    },
    currectMember() {
      return this.questionnaireMode === "DEFAULT"
        ? this.member
        : this.memberStudent;
    },
  },
  components: {
    QuestionnairenInputs,
  },
});
