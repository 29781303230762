
import { computed, defineComponent, inject, PropType } from 'vue-demi';
import { mapGetters } from 'vuex';
import portfolioCategory from "./category.vue";
import { currentTerm, FV, getCorrectInstrumentsCurrency } from '../../../../../../table/modules/container/calculation';
import { Questionnaire } from '@/interfaces/dto/questionnaire';
import { valueof } from '@/interfaces';
import { COURSES_ENUM, ROLES_ENUM } from '@/utils/enums';

export default defineComponent({
  name: "PortfolioCategories",
  props: {
    categories: {
      type:  Array as PropType<Array<Questionnaire.QSection>>,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
    portfolioId: {
      type: String,
      required: true,
    },
    portfolioName: {
      type: String,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
    core: [Number, String]
  },
  setup() {
    const targetCurrencyId = inject('targetCurrencyId');
    const targetCurrencySign = inject('targetCurrencySign');
    const status = inject('status');
    return { targetCurrencyId, targetCurrencySign, status }
  },
  provide() {
    return {
      total: computed(() => this.total),
      totalInstruments: computed(() => this.totalInstruments),
      targetTotal: computed(() => {
        const { inflation, profitability } = this.target.main.data;
        const term = this.target.type.sections[1].modules[0].data;
        const correctInflation = inflation / 100;
        const correctProfitability = (profitability / 100 ) || 0.10;
        const amount = this.target.type.sections[0].modules[0].data.amount;
        const current_term = currentTerm(term);

        let fv = Number(FV(
          amount,
          correctInflation,
          current_term
        ));
        const capital = (fv * 12) / correctProfitability;
        if (this.target.type.id === 2) fv = capital;

        if (this.course === COURSES_ENUM.ONE && this.uncombine) {
          fv = this.target.type.sections[3].modules[0].data.fv.replace(` ${this.targetCurrencySign}`, '');
        }

        if (this.portfolioName === 'expert') {
          fv = fv * (Number(this.core) / 100);
        }
        return Math.ceil10(fv, -2);
      }),
      expertTotal: computed(() => {
        const { inflation, profitability } = this.target.main.data;
        const term = this.target.type.sections[1].modules[0].data;
        const correctInflation = inflation / 100;
        const correctProfitability = (profitability / 100 ) || 0.10;
        const amount = this.target.type.sections[0].modules[0].data.amount;
        const current_term = currentTerm(term);

        let fv = Number(FV(
          amount,
          correctInflation,
          current_term
        ));
        const capital = (fv * 12) / correctProfitability;
        if (this.target.type.id === 2) fv = capital;

        if (this.course === COURSES_ENUM.ONE && this.uncombine) {
          fv = this.target.type.sections[3].modules[0].data.fv.replace(` ${this.targetCurrencySign}`, '');
        }

        return Math.ceil10(fv * (this.target.portfolios.expert.sections[0].modules[0].data.core / 100), -2);
      }),
      studentTotal: computed(() => {
        return this.target.type.sections[2].modules[0].data.amount;
      })
    }
  },
  computed: {
    ...mapGetters(['uncombine']),
    getFile() {
      return (this.$refs['tactic-' + this.portfolioId] as any).files[0];
    },
    onTacticFiles() {
      return this.$store.getters.role === ROLES_ENUM.STUDENT && this.course === COURSES_ENUM.TWO && this.status;
    },
    totalInstruments() {
      const arr = this.categories.map(c => c.modules).reduce((acc, arr) => acc.concat(arr));
      return arr
        .map(m => m.data)
        .filter(m => m.price);
    },
    total() {
      const arr = this.categories.map(c => c.modules).reduce((acc, arr) => acc.concat(arr));
      const result = arr
        .map(m => m.data)
        .filter(m => m.price);
      return getCorrectInstrumentsCurrency(result, String(this.targetCurrencyId), this.course);
    },
    totalPercent() {
      const arr = this.categories.map(c => c.modules).reduce((acc, arr) => acc.concat(arr));
      return Math.ceil10(arr
        .map(m => m.data)
        .filter(i => i.percent)
        .reduce((acc, i) => acc + Number(i.percent), 0), -2);
    },
    leftTotalPercent() {
      return Math.ceil10(100 - this.totalPercent <= 0 ? 0 : 100 - this.totalPercent, -2);
    }
  },
  components: {
    portfolioCategory,
  },
});
