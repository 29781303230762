import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fa78a28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "target--main color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionnaireInputs = _resolveComponent("QuestionnaireInputs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_QuestionnaireInputs, {
      module: _ctx.main,
      moduleStudent: _ctx.mainStudent,
      questionnaireMode: _ctx.questionnaireMode,
      course: _ctx.course,
      id: "target-main",
      targetId: _ctx.targetId
    }, null, 8, ["module", "moduleStudent", "questionnaireMode", "course", "targetId"])
  ]))
}