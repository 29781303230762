
import { defineComponent, PropType } from "vue-demi";
import { valueof } from "@/interfaces";
import { COURSES_ENUM } from "@/utils/enums";
import QuestionnaireSections from "../../common/sections/index.vue";

export default defineComponent({
  name: "TargetType",
  props: {
    type: {
      type: Object,
      required: true,
    },
    typeStudent: {
      type: Object,
      required: true,
    },
    targetId: {
      type: Number,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  components: {
    QuestionnaireSections,
  },
});
