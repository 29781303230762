import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ca54342"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "questionnaire--buttons flex justify-center"
}
const _hoisted_2 = {
  key: 0,
  class: "btn btn--qn btn--green btn--disabled"
}
const _hoisted_3 = {
  key: 2,
  class: "btn btn--qn btn--green btn--disabled"
}
const _hoisted_4 = {
  key: 1,
  class: "questionnaire--buttons flex justify-center"
}
const _hoisted_5 = {
  key: 0,
  class: "btn btn--qn btn--green btn--disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.role !== 'STUDENT')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.status && !_ctx.pending)
          ? (_openBlock(), _createElementBlock("button", _hoisted_2, " Закончить "))
          : (!_ctx.pending)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn--qn btn--green",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('finish')))
              }, " Закончить "))
            : (_ctx.pending)
              ? (_openBlock(), _createElementBlock("button", _hoisted_3, " Обработка... "))
              : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn btn--qn btn--border",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('modeChange')))
        }, _toDisplayString(_ctx.mode === 'list' ? 'Портфель' : 'В анкету'), 1),
        (!_ctx.status && _ctx.mode !== 'list')
          ? (_openBlock(), _createElementBlock("button", _hoisted_5, "Отправить на проверку"))
          : (!_ctx.pending && _ctx.mode !== 'list')
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn--qn btn--green",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.commit('createModal', { id: 'verification', data: {} })))
              }, "Отправить на проверку"))
            : _createCommentVNode("", true)
      ]))
}