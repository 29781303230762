import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e53df74"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "table--container"
}
const _hoisted_2 = {
  key: 1,
  class: "table--container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_table = _resolveComponent("common-table")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["table", {green: _ctx.portfolioId === 'existingPortfolio', yellow: _ctx.portfolioId === 'studentPortfolio'}])
  }, [
    (_ctx.core)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coreTypes, (_type) => {
            return (_openBlock(), _createBlock(_component_common_table, {
              key: _type.id,
              course: _ctx.course,
              portfolioId: _ctx.portfolioId,
              length: _ctx.modules.length,
              instrumentArray: _ctx.getCurrentInstruments(_type.id),
              type: _type
            }, null, 8, ["course", "portfolioId", "length", "instrumentArray", "type"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_common_table, {
            course: _ctx.course,
            portfolioId: _ctx.portfolioId,
            length: _ctx.modules.length,
            instrumentArray: _ctx.modules.filter((m) => m.data.name).map((m) => m.data)
          }, null, 8, ["course", "portfolioId", "length", "instrumentArray"])
        ]))
  ], 2))
}