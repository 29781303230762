
import { defineComponent, PropType } from "vue-demi";
import { COURSES_ENUM, ROLES_ENUM } from "@/utils/enums";
import QuestionnaireSections from "../../common/sections/index.vue";
import { valueof } from "@/interfaces";

export default defineComponent({
  name: "TargetConclusion",
  props: {
    conclusion: {
      type: Object,
      required: true,
    },
    conclusionStudent: {
      type: Object,
      required: true,
    },
    targetId: {
      type: Number,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true,
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    targetCurrencySign: {
      type: String,
      default: "",
    },
  },
  components: {
    QuestionnaireSections,
  },
  computed: {
    isStudent() {
      return (
        this.$store.getters.user &&
        this.$store.getters.user.role !== ROLES_ENUM.STUDENT
      );
    },
  },
});
