import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14a2f2f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "comment",
  "data-id": "comment"
}
const _hoisted_2 = { class: "comment--title" }
const _hoisted_3 = { class: "comment--container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionnairenInputs = _resolveComponent("QuestionnairenInputs")!

  return (_ctx.role !== 'STUDENT')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.comment.name), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_QuestionnairenInputs, {
            questionnaireMode: _ctx.questionnaireMode,
            module: _ctx.comment.module,
            id: _ctx.comment.id
          }, null, 8, ["questionnaireMode", "module", "id"])
        ])
      ]))
    : _createCommentVNode("", true)
}