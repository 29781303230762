import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1561531e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-id"]
const _hoisted_2 = { class: "member--title" }
const _hoisted_3 = { class: "member--container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionnairenInputs = _resolveComponent("QuestionnairenInputs")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["member", { color: _ctx.color }]),
    "data-id": _ctx.member.id
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.member.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_QuestionnairenInputs, {
        block: !!(_ctx.questionnaireMode === 'GAP') && _ctx.course === 'one',
        questionnaireMode: _ctx.questionnaireMode,
        module: _ctx.member.module,
        id: _ctx.member.id,
        course: _ctx.course
      }, null, 8, ["block", "questionnaireMode", "module", "id", "course"])
    ])
  ], 10, _hoisted_1))
}