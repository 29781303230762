import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bf35fdb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-id"]
const _hoisted_2 = {
  key: 0,
  class: "portfolio--description"
}
const _hoisted_3 = {
  key: 1,
  class: "portfolio--actions"
}
const _hoisted_4 = {
  key: 0,
  class: "btn btn--qn btn--orange btn--disabled"
}
const _hoisted_5 = {
  key: 2,
  class: "btn btn--qn btn--orange btn--disabled"
}
const _hoisted_6 = {
  key: 2,
  class: "portfolio--balance"
}
const _hoisted_7 = { class: "portfolio--container" }
const _hoisted_8 = ["data-id"]
const _hoisted_9 = {
  key: 0,
  class: "portfolio--description"
}
const _hoisted_10 = {
  key: 1,
  class: "portfolio--actions"
}
const _hoisted_11 = {
  key: 0,
  class: "btn btn--qn btn--orange btn--disabled"
}
const _hoisted_12 = {
  key: 2,
  class: "btn btn--qn btn--orange btn--disabled"
}
const _hoisted_13 = {
  key: 3,
  class: "portfolio--container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portfolio_balance = _resolveComponent("portfolio-balance")!
  const _component_portfolio_table = _resolveComponent("portfolio-table")!
  const _component_QuestionnaireSections = _resolveComponent("QuestionnaireSections")!
  const _component_PortfolioCalculation = _resolveComponent("PortfolioCalculation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "portfolioAnimation" }, {
      default: _withCtx(() => [
        (_ctx.currentSection && _ctx.questionnaireVersion === 'new' && _ctx.checkSection(_ctx.portfolioId))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["portfolio color", { dNone: !_ctx.checkSection(_ctx.portfolioId) }]),
              "data-id": _ctx.portfolioId
            }, [
              (_ctx.portfolioStatus && _ctx.questionnaireMode !== 'GAP')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.portfolio.description), 1))
                : _createCommentVNode("", true),
              (_ctx.portfolioStatus && _ctx.questionnaireMode !== 'GAP')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (!_ctx.questionnaireStatus)
                      ? (_openBlock(), _createElementBlock("button", _hoisted_4, "Есть инструменты"))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "btn btn--qn btn--orange",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSectionStatus(_ctx.portfolioId, 1)))
                        }, "Есть инструменты")),
                    (!_ctx.questionnaireStatus)
                      ? (_openBlock(), _createElementBlock("button", _hoisted_5, "Нет инструментов"))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 3,
                          class: "btn btn--qn btn--orange",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSectionStatus(_ctx.portfolioId, -1)))
                        }, "Нет инструментов"))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.questionnaireMode === 'GAP' && _ctx.portfolioId !== 'expert')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_portfolio_balance, {
                      course: _ctx.course,
                      portfolio: _ctx.portfolioId !== 'expert' ? _ctx.portfolioStudent : _ctx.portfolio,
                      targetCurrencyId: _ctx.targetCurrencyId
                    }, null, 8, ["course", "portfolio", "targetCurrencyId"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.portfolioId === 'expert')
                ? (_openBlock(), _createBlock(_component_portfolio_table, {
                    key: 3,
                    class: _normalizeClass([
          { dNone: !_ctx.checkSection(_ctx.portfolioId) },
        ]),
                    course: _ctx.course,
                    targetId: _ctx.targetId,
                    portfolio: _ctx.portfolio,
                    portfolioId: _ctx.portfolioId
                  }, null, 8, ["class", "course", "targetId", "portfolio", "portfolioId"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_QuestionnaireSections, {
                  id: "target-portfolio",
                  course: _ctx.course,
                  sections: _ctx.portfolio.sections,
                  sectionsStudent: _ctx.portfolioStudent.sections,
                  targetId: _ctx.targetId,
                  portfolioId: _ctx.portfolioId,
                  sectionStatus: _ctx.sectionStatus,
                  questionnaireMode: _ctx.questionnaireMode
                }, null, 8, ["course", "sections", "sectionsStudent", "targetId", "portfolioId", "sectionStatus", "questionnaireMode"]),
                (_ctx.isCalculationAccess)
                  ? (_openBlock(), _createBlock(_component_PortfolioCalculation, {
                      key: 0,
                      sections: _ctx.portfolio.sections,
                      targetId: _ctx.targetId,
                      portfolioId: _ctx.portfolioId,
                      course: _ctx.course
                    }, null, 8, ["sections", "targetId", "portfolioId", "course"]))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.questionnaireVersion === 'old')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["portfolio", [
      { dNone: !_ctx.checkSection(_ctx.portfolioId) },
      { color: _ctx.role === 'EXPERT' },
    ]]),
          "data-id": _ctx.portfolioId
        }, [
          (_ctx.portfolioStatus)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.portfolio.description), 1))
            : _createCommentVNode("", true),
          (_ctx.portfolioStatus)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (!_ctx.questionnaireStatus)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_11, "Есть инструменты"))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn btn--qn btn--orange",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSectionStatus(_ctx.portfolioId, 1)))
                    }, "Есть инструменты")),
                (!_ctx.questionnaireStatus)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_12, "Нет инструментов"))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 3,
                      class: "btn btn--qn btn--orange",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSectionStatus(_ctx.portfolioId, -1)))
                    }, "Нет инструментов"))
              ]))
            : _createCommentVNode("", true),
          (_ctx.portfolioId === 'expert')
            ? (_openBlock(), _createBlock(_component_portfolio_table, {
                key: 2,
                class: _normalizeClass({dNone: !_ctx.checkSection(_ctx.portfolioId) }),
                course: _ctx.course,
                targetId: _ctx.targetId,
                portfolio: _ctx.portfolio,
                portfolioId: _ctx.portfolioId
              }, null, 8, ["class", "course", "targetId", "portfolio", "portfolioId"]))
            : _createCommentVNode("", true),
          (_ctx.sectionStatus[_ctx.portfolioId] !== -1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(_component_QuestionnaireSections, {
                  id: "target-portfolio",
                  course: _ctx.course,
                  sections: _ctx.portfolio.sections,
                  sectionsStudent: _ctx.portfolioStudent.sections,
                  targetId: _ctx.targetId,
                  portfolioId: _ctx.portfolioId,
                  sectionStatus: _ctx.sectionStatus,
                  questionnaireMode: _ctx.questionnaireMode
                }, null, 8, ["course", "sections", "sectionsStudent", "targetId", "portfolioId", "sectionStatus", "questionnaireMode"]),
                _createVNode(_component_PortfolioCalculation, {
                  sections: _ctx.portfolio.sections,
                  targetId: _ctx.targetId,
                  portfolioId: _ctx.portfolioId,
                  course: _ctx.course
                }, null, 8, ["sections", "targetId", "portfolioId", "course"])
              ]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_8))
      : _createCommentVNode("", true)
  ], 64))
}