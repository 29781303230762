
import { defineComponent, inject } from "vue-demi";

export default defineComponent({
  name: "QuestionnaireButtons",
  emits: ["modeChange", "finish"],
  props: {
    pending: {
      type: Boolean,
      required: true
    },
    status: {
      type: Boolean,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  setup() {
    const role = inject('role');
    return { role }
  }
});
