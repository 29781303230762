
import { defineComponent, inject } from 'vue-demi';
import QuestionnairenInputs from '../common/inputs/index.vue';

export default defineComponent({
  name: 'QuestionnaireComment',
  props: {
    comment: {
      type: Object,
      required: true
    },
    questionnaireMode: {
      type: String,
      required: true,
    },
  },
  setup() {
    const role = inject('role');
    return { role }
  },
  components: {
    QuestionnairenInputs
  }
})
