import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2249d1c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "insurance",
  "data-id": "insurance"
}
const _hoisted_2 = { class: "insurance--title" }
const _hoisted_3 = { class: "insurance--container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionnairenInputs = _resolveComponent("QuestionnairenInputs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.currectInsurance.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_QuestionnairenInputs, {
        block: !!(_ctx.questionnaireMode === 'GAP'),
        questionnaireMode: _ctx.questionnaireMode,
        module: _ctx.currectInsurance.module,
        id: _ctx.currectInsurance.id
      }, null, 8, ["block", "questionnaireMode", "module", "id"])
    ])
  ]))
}